@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --col-1: 8.33%;
    --col-2: 16.66%;
    --col-3: 25%;
    --col-4: 33.33%;
    --col-5: 41.66%;
    --col-6: 50%;
    --col-7: 58.33%;
    --col-8: 66.66%;
    --col-9: 75%;
    --col-10: 83.33%;
    --col-11: 91.66%;
    --col-12: 100%;

    --color-primary: rgba(163, 67, 2);

    --color-primary-50: #A36102;
    --color-primary-100: #A35702;
    --color-primary-200: #A35202;
    --color-primary-300: #A34D02;
    --color-primary-400: #A34802;
    --color-primary-500: #A34302;
    --color-primary-600: #A33E02;
    --color-primary-700: #A33902;
    --color-primary-800: #A33402;
    --color-primary-900: #A32F02;

    --layout-breakpoint-xs: 600px;
    --layout-breakpoint-sm: 768px;
    --layout-breakpoint-md: 992px;
    --layout-breakpoint-lg: 1200px;
}

a.text-underlined {
    text-decoration: underline;
    color: inherit;
}

a.text-normal {
    text-decoration: none;
    color: inherit;
}
/* 
body {
    height: 100%;
    box-sizing: border-box;
    margin: 0;
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
} */

body {
    height: 100%;
    box-sizing: border-box;
    margin: 0;
    font-family: "Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
} 

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
    box-sizing: border-box;
    height: 100%;
}

html {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}