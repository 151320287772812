@import "../index.css";

.app-container {
    position: relative;
    height: max-content;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.main-page-outlet-container {
    height: max-content;
    width: 100%;
    box-sizing: border-box;
    position: relative;
}

.home-container {
    height: inherit;
    box-sizing: border-box;
    background-color: inherit;
    width: 100%;
}

.home-horizontal-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    padding-top: 10px;
    gap: 10px;
    width: 100%;
    margin-right: 30rem;
    height: max-content;
    box-sizing: border-box;
}