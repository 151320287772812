.navbar {
    box-sizing: border-box;
    background-color: #A34302;
    display: flex;
    justify-content: space-between;
    padding: 10px 40px;
    align-items: center;
    width: 100%;
}

.category-bar {
    display: flex;
    justify-content: center;
    padding: 5px;
    position: sticky;
    align-items: center;
}


a {
    text-decoration: none;
    text-decoration-color: white;
}

.navbar-options-list {
    display: flex;
    flex-direction: row;
    align-items: center;
}