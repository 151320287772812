@import "../../index.css";

.trending-news-text {
    text-align: justify;
}

.trending-news-container {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    position: relative;
}

@media only screen and (min-width: 600px) {

    .trending-news-text {
        max-width: 80%;
    }
}

@media only screen and (max-width: 600px) {

    .trending-news-text {
        max-width: 95%;
    }

}

.trending-news-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}