.bantou-club-container {
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px 0;
    box-sizing: border-box;
    border-radius: 10px;
}

.bantou-club-debate-image {
    width: 100%;
    height: 15rem;
    object-fit: cover;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    background-color: #f5ebde;
}

td {
    text-align: center;
    padding: 12px 18px;
    width: 100%;
}

th {
    background-color: #c96e38;
    border: 1px solid var(--color-primary-600);
    padding: 8px;
}