.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px 10%;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 15px;
}

h1 {
    color: #333;
}

p {
    font-size: 16px;
    line-height: 1.6;
    color: #666;
}

.highlight {
    font-weight: bold;
    color: var(--color-primary);
}