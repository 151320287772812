.live-tv-collapsed-container {
    z-index: 2;
    bottom: 0;
    left: 0;
    margin: 20px;
    transition: height 0.3s, width 0.3s;
    box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;
}

.live-tv-expanded-container {
    background-color: #303030;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
    transition: height 0.3s, width 0.3s;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-sizing: border-box;
}

.live-tv-video-stream {
    width: 100%;
    object-fit: cover;
    cursor: pointer;
}

.live-tv-mobile-collapsed-container {
    box-shadow: rgba(0, 0, 0, 0.25) 0 14px 28px, rgba(0, 0, 0, 0.22) 0 10px 10px;
}