

.article-page-img {
    height: 25rem;
    width: auto;
    object-fit: cover;
}

.article-story-title-container {
    color: white;
    background-color: var(--color-primary);
    padding: 5px 15px;
    width: max-content;
}
