.section-item-header-img {
    height: auto;
    width: 100%;
    object-fit: cover;
}

.section-title-container {
    color: white;
    background-color: var(--color-primary);
    padding: 3px 5px;
    font-size: medium;
    text-transform: capitalize;
    width: max-content;
}