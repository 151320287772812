@import "../../index.css";

.article1-img-container {
    height: inherit;
    width: 120px;
}

.article-img {
    width: 100%;
    height: 180px;
    object-fit: cover;
}

.article-img-small {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 7px 0 0 7px;
}

.article2-img-container {
    height: inherit;
    width: 100px;
}

.article1-category-container {
    color: white;
    background-color: var(--color-primary);
    padding: 3px;
    font-size: small;
}

.article1-card-content {
    padding: 10px;
    gap: 13px
}

a:hover {
    text-decoration: underline;
    color: inherit;
}

.video-article-container {
    position: relative;
}

.video-article-overlay-container {
    top: 0;
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.56);
    align-items: center;
    justify-content: center;
}

.video-article-overlay-container:hover {
    cursor: pointer;
}

.video-article1-video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
}

.video-article2-video {
    cursor: pointer;
    height: 150px;
    width: 100%;
    object-fit: cover;
    position: relative;
}

